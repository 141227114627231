// import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
// import { useEffect, useState } from "react";
// import logo from "../assets/images/ssy-logo.svg";
// import { RaisingBox } from "../components/raisingBox/raisingBox";
// import Seo from "../components/seo/seo";
// import { SsyIntersectionObserver } from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
// import { SsyLink } from "../components/ssyLink/ssyLink";
// import style from "../assets/css/main.module.css";
// import "../assets/css/marthas.css";
// import { NewSlider } from "../components/newSlider/newSlider";
// import { DownloadButton } from "../components/downloadButton/downloadButton";
// import { FeatureItem } from "../components/featureItem/featureItem";
// import { SSYSlider } from "../components/slider/slider";
// import AnimatedHero2 from "../components/animatedHero/animatedHero";
// import { Link } from "gatsby";

// const featuresData = [
//   {
//     image: "camera",
//     text: "LANDING_FEATURE_1_TITLE_MARTHAS",
//   },
//   {
//     image: "screen",
//     text: "LANDING_FEATURE_2_TITLE",
//   },
//   {
//     image: "map",
//     text: "LANDING_FEATURE_3_TITLE",
//   },
//   {
//     image: "comment",
//     text: "LANDING_FEATURE_4_TITLE_MARTHAS",
//   },
//   {
//     image: "print",
//     text: "LANDING_FEATURE_5_TITLE_MARTHAS",
//   },
// ];
// const config = [
//   {
//     image: "how1",
//     name: "LANDING_HOW_TO_1_BODY",
//     text: "LANDING_HOW_TO_1_TITLE",
//   },
//   {
//     image: "how2",
//     name: "LANDING_HOW_TO_2_BODY",
//     text: "LANDING_HOW_TO_2_TITLE",
//   },
//   {
//     image: "how3",
//     name: "LANDING_HOW_TO_3_BODY",
//     text: "LANDING_HOW_TO_3_TITLE",
//   },
// ];

export const MarthasPage = () => {

  return <></>;
  // const intl = useIntl();
  // const [downloadWhere, setDownloadWhere] = useState({
  //   device: "NAVIGATOR",
  // });

  // const [trackable, setTrackable] = useState();

  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor;
  //   let device = "NAVIGATOR";
  //   if (/android/i.test(userAgent)) {
  //     device = "ANDROID";
  //   }
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     device = "IOS";
  //   }
  //   setDownloadWhere({
  //     device,
  //   });

  //   const urlSearchParams = new URLSearchParams(props.location.search);
  //   const source = urlSearchParams.get("utm_campaign");
  //   const trackable =
  //     source === "marthas-flyer"
  //       ? process.env.GATSBY_PARTNERID_MARTHAS_FLYER
  //       : process.env.GATSBY_PARTNERID_MARTHAS;
  //   setTrackable(trackable);
  // }, []);

  // return (
  //   <>
  //     <Seo
  //       title="Tutte le foto della festa nella tua App"
  //       url={props.location.pathname}
  //       lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
  //       image="https://eu-media.samsaidyes.com/system/static/img/marthas-banner.jpg"
  //       description="NOVITÀ! Martha's Cottage ti aiuta a raccogliere tutte le foto della tua festa con l'App Sam"
  //     />

  //     <div id="DIV_1">
  //       <div id="DIV_2">
  //         <div id="DIV_3">
  //           <a href="https://marthascottage.com/" id="A_4">
  //             <img
  //               alt="Martha's Cottage Brand Site Logo"
  //               src="https://marthascottage.com/wp-content/uploads/marthascottage-logo.png"
  //               id="IMG_5"
  //             />
  //           </a>{" "}
  //         </div>
  //         <div id="DIV_7">
  //           <ul id="UL_8">
  //             <li id="LI_9">
  //               <a href="https://marthascottage.com/shop/" id="A_10">
  //                 SHOP
  //               </a>
  //             </li>
  //             <li id="LI_11">
  //               <a href="https://marthascottage.com/magazine/" id="A_12">
  //                 MAGAZINE
  //               </a>
  //             </li>
  //             <li id="LI_13">
  //               <a href="https://marthascottage.com/le-mie-nozze/" id="A_14">
  //                 LE MIE NOZZE
  //               </a>
  //             </li>
  //             <li id="LI_15">
  //               <a href="https://marthascottage.com/fornitori/" id="A_16">
  //                 FORNITORI
  //               </a>
  //             </li>
  //             <li id="LI_17">
  //               <a href="https://marthascottage.com/abiti/" id="A_18">
  //                 ABITI
  //               </a>
  //             </li>
  //             <li id="LI_19">
  //               <a href="https://marthascottage.com/prodotti/corsi/" id="A_20">
  //                 CORSI
  //               </a>
  //             </li>
  //             <li id="LI_21">
  //               <a href="https://marthascottage.com/franchising/" id="A_22">
  //                 FRANCHISING
  //               </a>
  //             </li>
  //             <li id="LI_23">
  //               <a href="https://marthascottage.com/" id="A_24">
  //                 VIAGGI
  //               </a>
  //             </li>
  //           </ul>
  //         </div>
  //         <a id="SPAN_6" href="https://marthascottage.com/">
  //           <span>☰</span>
  //         </a>
  //       </div>
  //     </div>

  //     <Link to="/" className={style.poweredBySam}>
  //       <span>powered by</span>
  //       <img
  //         className={style.logo}
  //         width={40}
  //         height={40}
  //         src={logo}
  //         alt="ssy logo"
  //       />
  //     </Link>

  //     <main className={style.pageStyles}>
  //       <SsyIntersectionObserver>
  //         <RaisingBox>
  //           <AnimatedHero2 isMartha={true} />
  //           <section className={`${style.firstSection} ${style.section}`}>
  //             <h1>Tutte le foto della festa nella tua App</h1>
  //             <p className={`${style.subtitle} ${style.subtitleMartha}`}>
  //               <strong>NOVITÀ!</strong> Martha's Cottage ti aiuta a raccogliere
  //               tutte le foto della tua festa con l'App Sam
  //             </p>
  //             <SsyLink
  //               to={`${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage({
  //                 id: "MAIN_LANGUAGE",
  //               })}/register?t=${trackable}`}
  //               id="LANDING_MAIN_BUTTON"
  //               type="mainCta"
  //               internal={false}
  //               label={"Clicca qui"}
  //             />
  //             <span className={style.instructions}></span>
  //           </section>
  //         </RaisingBox>
  //       </SsyIntersectionObserver>
  //       <SsyIntersectionObserver>
  //         <SSYSlider
  //           customTitle={"Semplice per te e i tuoi ospiti"}
  //           data={config}
  //           customButtonLabel={"Inizia subito"}
  //           autoplay={false}
  //           trackId={process.env.GATSBY_PARTNERID_MARTHAS}
  //           whiteDots={true}
  //           withBackground={false}
  //         />
  //       </SsyIntersectionObserver>
  //       <SsyIntersectionObserver>
  //         <RaisingBox>
  //           <section
  //             className={`${style.forthSection} ${style.section} commentsSection`}
  //           >
  //             <h2 className={style.titleForth}>
  //               {intl.formatMessage({ id: "LANDING_COSTUMERS_TITLE" })}
  //             </h2>
  //             <p className={style.subtitleForth}>
  //               {intl.formatMessage({ id: "LANDING_COSTUMERS_BODY" })}
  //             </p>
  //             <NewSlider
  //               translationPrefix="LANDING_TESTIMONIAL"
  //               intl={intl}
  //               data={[1, 2, 3, 4, 2]}
  //             />
  //             <NewSlider
  //               translationPrefix="LANDING_TESTIMONIAL"
  //               intl={intl}
  //               delayed={true}
  //               data={[5, 6, 7, 8, 9]}
  //             />
  //           </section>
  //         </RaisingBox>
  //       </SsyIntersectionObserver>
  //       <SsyIntersectionObserver>
  //         <RaisingBox>
  //           <section
  //             className={`${style.fifthSection} ${style.fifthSectionMarthas} ${style.section}`}
  //           >
  //             <h2 className={style.fifthSectionTitle}>
  //               I tuoi vantaggi con l'App
  //             </h2>
  //             <ul>
  //               {featuresData.map((item, i) => (
  //                 <FeatureItem
  //                   key={i}
  //                   isMartha={true}
  //                   image={item.image}
  //                   text={intl.formatMessage({ id: item.text })}
  //                 />
  //               ))}
  //             </ul>
  //           </section>
  //         </RaisingBox>
  //       </SsyIntersectionObserver>
  //       <SsyIntersectionObserver>
  //         <RaisingBox>
  //           <section className={style.sixSection}>
  //             <h2>{intl.formatMessage({ id: "LANDING_DOWNLOAD_TITLE" })}</h2>
  //             {downloadWhere.device !== "ANDROID" && (
  //               <DownloadButton
  //                 id="LANDING_APP_STORE_BUTTON"
  //                 href={`${process.env.GATSBY_IOS_URL}${intl.formatMessage({
  //                   id: "MAIN_LANGUAGE",
  //                 })}`}
  //                 isApple={true}
  //               />
  //             )}
  //             {downloadWhere.device !== "IOS" && (
  //               <DownloadButton
  //                 id="LANDING_PLAY_STORE_BUTTON"
  //                 href={`${process.env.GATSBY_ANDROID_URL}`}
  //                 isApple={false}
  //               />
  //             )}
  //           </section>
  //         </RaisingBox>
  //       </SsyIntersectionObserver>
  //       <SsyIntersectionObserver>
  //         <section
  //           className={`${style.sevenSectionMarthas} ${style.sevenSection}`}
  //         >
  //           <RaisingBox>
  //             <h2>{intl.formatMessage({ id: "LANDING_IDEAS_TITLE" })}</h2>
  //             <NewSlider
  //               rates={false}
  //               translationPrefix="LANDING_IDEA"
  //               intl={intl}
  //               data={[1, 2, 3, 1, 3]}
  //             />
  //             <NewSlider
  //               rates={false}
  //               translationPrefix="LANDING_IDEA"
  //               intl={intl}
  //               delayed={true}
  //               data={[4, 5, 6, 4, 5]}
  //             />
  //             <div className={style.buttonContainer}>
  //               <SsyLink
  //                 to={`${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage(
  //                   {
  //                     id: "MAIN_LANGUAGE",
  //                   }
  //                 )}/register?t=${process.env.GATSBY_PARTNERID_MARTHAS}`}
  //                 type="primary"
  //                 id="LANDING_IDEAS_BUTTON"
  //                 internal={false}
  //                 label={intl.formatMessage({
  //                   id: "LANDING_IDEAS_BUTTON",
  //                 })}
  //               />
  //             </div>
  //           </RaisingBox>
  //         </section>
  //       </SsyIntersectionObserver>
  //       <SsyIntersectionObserver>
  //         <RaisingBox>
  //           <section className={style.eightSection}>
  //             <h2>{intl.formatMessage({ id: "LANDING_QUESTIONS_TITLE" })}</h2>
  //             <p>{intl.formatMessage({ id: "LANDING_QUESTIONS_BODY" })}</p>
  //             <SsyLink
  //               to={`${intl.formatMessage({
  //                 id: "FOOTER_SUPPORT_CONTACT_LINK",
  //               })}`}
  //               type="primary"
  //               id="LANDING_CONTACT_BUTTON"
  //               internal={false}
  //               label={intl.formatMessage({
  //                 id: "LANDING_QUESTIONS_BUTTON",
  //               })}
  //             />
  //           </section>
  //         </RaisingBox>
  //       </SsyIntersectionObserver>
  //     </main>

  //     <div id="FOOTERDIV_1">
  //       <div id="FOOTERDIV_2">
  //         <h2 id="FOOTERH2_3">Il mondo di Martha</h2>
  //         <div id="FOOTERDIV_4">
  //           <div id="FOOTERDIV_5">
  //             <p id="FOOTERP_6">
  //               <span id="FOOTERSPAN_7">284.339</span>
  //               <br id="FOOTERBR_8" /> Matrimoni
  //               <br id="FOOTERBR_9" /> Serviti
  //             </p>
  //           </div>
  //           <div id="FOOTERDIV_10">
  //             <p id="FOOTERP_11">
  //               <span id="FOOTERSPAN_12">3.216.124</span>
  //               <br id="FOOTERBR_13" /> Prodotti
  //               <br id="FOOTERBR_14" /> Spediti
  //             </p>
  //           </div>
  //           <div id="FOOTERDIV_15">
  //             <p id="FOOTERP_16">
  //               <span id="FOOTERSPAN_17">63.954.749</span>
  //               <br id="FOOTERBR_18" /> Confetti
  //               <br id="FOOTERBR_19" /> Venduti
  //             </p>
  //           </div>
  //           <div id="FOOTERDIV_20">
  //             <p id="FOOTERP_21">
  //               <span id="FOOTERSPAN_22">165.792</span>
  //               <br id="FOOTERBR_23" /> Fan sui
  //               <br id="FOOTERBR_24" /> Social
  //             </p>
  //           </div>
  //         </div>
  //         <div id="FOOTERDIV_25"></div>
  //         <div id="FOOTERDIV_26">
  //           <div id="FOOTERDIV_27">
  //             <h4 id="FOOTERH4_28">CHI SIAMO</h4>
  //             <p id="FOOTERP_29">
  //               Martha’s Cottage è il più grande ecommerce dedicato al
  //               matrimonio in Europa. Sono oltre 250mila gli sposi che si sono
  //               serviti del nostro store online. Raccomandato dai migliori
  //               Wedding Planner e con una grande rete di fornitori locali con
  //               Martha’s Cottage rendi unico e indimenticabile il tuo
  //               Matrimonio!
  //             </p>
  //           </div>
  //           <div id="FOOTERDIV_30">
  //             <h4 id="FOOTERH4_31">INFORMAZIONI</h4>
  //             <ul id="FOOTERUL_32">
  //               <li id="FOOTERLI_33">
  //                 <a
  //                   href="https://marthascottage.com/chi-siamo/"
  //                   id="FOOTERA_34"
  //                 >
  //                   Chi Siamo
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_35">
  //                 <a
  //                   href="https://marthascottage.com/contatti/"
  //                   id="FOOTERA_36"
  //                 >
  //                   Contattaci
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_37">
  //                 <a
  //                   href="https://marthascottage.com/franchising/"
  //                   id="FOOTERA_38"
  //                 >
  //                   Apri un negozio Martha’s Cottage
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_39">
  //                 <a
  //                   rel="noopener"
  //                   href="https://marthascottage.com/modulo_di_recesso.pdf"
  //                   id="FOOTERA_40"
  //                 >
  //                   Modulo di recesso
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_41">
  //                 <a
  //                   href="https://marthascottage.com/termini-e-condizioni/"
  //                   id="FOOTERA_42"
  //                 >
  //                   Termini e Condizioni
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_43">
  //                 <a
  //                   href="https://marthascottage.com/politica-sulla-privacy/"
  //                   id="FOOTERA_44"
  //                 >
  //                   Privacy Policy
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_45">
  //                 <a
  //                   href="https://marthascottage.com/cookie-policy/"
  //                   id="FOOTERA_46"
  //                 >
  //                   Cookie policy
  //                 </a>
  //               </li>
  //             </ul>
  //           </div>
  //           <div id="FOOTERDIV_47">
  //             <h4 id="FOOTERH4_48">MARTHA IN EUROPA</h4>
  //             <ul id="FOOTERUL_49">
  //               <li id="FOOTERLI_50">
  //                 <a href="https://marthascottage.com" id="FOOTERA_51">
  //                   Italia
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_52">
  //                 <a href="https://marthascottage.com" id="FOOTERA_53">
  //                   Francia
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_54">
  //                 <a href="https://marthascottage.com" id="FOOTERA_55">
  //                   Germania
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_56">
  //                 <a href="https://marthascottage.com" id="FOOTERA_57">
  //                   Spagna
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_58">
  //                 <a href="https://marthascottage.com" id="FOOTERA_59">
  //                   Inghilterra
  //                 </a>
  //               </li>
  //               <li id="FOOTERLI_60">
  //                 <a href="https://marthascottage.com" id="FOOTERA_61">
  //                   Romania
  //                 </a>
  //               </li>
  //             </ul>
  //           </div>
  //           <div id="FOOTERDIV_62">
  //             <h4 id="FOOTERH4_63">DOVE SIAMO</h4>
  //             <p id="FOOTERP_64">
  //               Siracusa
  //               <br id="FOOTERBR_65" /> SP 114, n128-130
  //             </p>
  //             <p id="FOOTERP_66">
  //               Napoli
  //               <br id="FOOTERBR_67" /> Via Piave, n22
  //             </p>
  //             <p id="FOOTERP_68">
  //               Milano
  //               <br id="FOOTERBR_69" /> Via Villoresi, n5
  //             </p>
  //           </div>
  //         </div>
  //         <div id="FOOTERDIV_70"></div>
  //         <div id="FOOTERDIV_71">
  //           <div id="FOOTERDIV_72">
  //             <div id="FOOTERDIV_73">
  //               <p id="FOOTERP_74">
  //                 © 2023 Martha's Cottage Srl - P.IVA 01800620898 - REA
  //                 SR-149326
  //               </p>
  //             </div>
  //             <div id="FOOTERDIV_75"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default MarthasPage;
